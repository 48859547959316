// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_link__wLXEj{text-decoration:none;transition:color 0.1s ease;cursor:pointer}.styles_link__wLXEj:hover{transition:none}.styles_variantNormal__19IZo{color:#7949FF}.styles_variantClean__1xf4k{color:#000;color:initial;transition:none}.styles_variantClean__1xf4k:hover{color:#000;color:initial}\n", "",{"version":3,"sources":["webpack://styles.module.scss"],"names":[],"mappings":"AAAA,oBACE,oBAAqB,CACrB,0BAA2B,CAC3B,cAAe,CAHjB,0BAMI,eAAgB,CACjB,6BAID,aAAc,CACf,4BAGC,UAAc,CAAd,aAAc,CACd,eAAgB,CAFlB,kCAKI,UAAA,CAAA,aAAc","sourcesContent":[".link {\n  text-decoration: none;\n  transition: color 0.1s ease;\n  cursor: pointer;\n\n  &:hover {\n    transition: none;\n  }\n}\n\n.variantNormal {\n  color: #7949FF;\n}\n\n.variantClean {\n  color: initial;\n  transition: none;\n\n  &:hover {\n    color: initial;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "styles_link__wLXEj",
	"variantNormal": "styles_variantNormal__19IZo",
	"variantClean": "styles_variantClean__1xf4k"
};
export default ___CSS_LOADER_EXPORT___;
