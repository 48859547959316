/**
 * Компонент является легкой оберткой над eact-textarea-autosize,
 * кастомизируя стили и некоторое поведение.
 * Подробную документацию можно найти на сайте https://andreypopp.github.io/react-textarea-autosize
 */

import cn from 'classnames'
import React, { useRef } from 'react'
import TextAreaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize'

import useAutoFocus from 'hooks/useAutoFocus'

import styles from './styles.module.scss'

export type TextareaProps = Omit<TextareaAutosizeProps, 'ref'>

export function TextArea(props: TextareaProps) {
  const { className, ...restProps } = props
  const textareaRef = useRef(null)

  useAutoFocus(textareaRef, props)

  return <TextAreaAutosize className={cn(styles.TextArea, className)} {...restProps} />
}
