import React from 'react'
import cn from 'classnames'
import { chakra, ChakraProps } from '@chakra-ui/system'
import { Spinner } from 'components/Spinner'
import styles from './styles.module.scss'

const VARIANT_TO_CLASSNAME = {
  basic: styles.variantBasic,
  clean: styles.variantClean,
}

const SIZE_TO_CLASSNAME = {
  medium: styles.sizeMedium,
}

interface BaseButtonProps
  extends Omit<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'color'
  > {
  loading?: boolean
  size?: 'medium'
  // Стиль кнопки, влияет на цвет фона и текста кнопки
  variant?: 'basic' | 'clean'
}

export interface ButtonProps extends ChakraProps, BaseButtonProps {}

export const Button = chakra((props: BaseButtonProps) => {
  const { size = 'medium', variant = 'basic', children, className, loading, ...restProps } = props

  return (
    <button
      type='button'
      {...restProps}
      className={cn(
        styles.button,
        className,
        variant && VARIANT_TO_CLASSNAME[variant],
        size && SIZE_TO_CLASSNAME[size],
        loading && styles.loading,
      )}
    >
      {children}
      {loading && <Spinner centered variant={{ color: '#B4C0CA', innerColor: '#dfe3e8' }} />}
    </button>
  )
})
