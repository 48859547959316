// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_TextArea__3J5XF{outline:none}\n", "",{"version":3,"sources":["webpack://styles.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAa","sourcesContent":[".TextArea {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextArea": "styles_TextArea__3J5XF"
};
export default ___CSS_LOADER_EXPORT___;
