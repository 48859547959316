import React, { useEffect } from 'react'
import { isFunction } from 'lodash'

export default function useAutoFocus(
  ref: React.RefObject<any>, // TODO: type properly
  props: { autoFocus?: boolean },
) {
  const { autoFocus } = props

  useEffect(() => {
    if (!isFunction(ref.current?.focus)) return

    ref.current.focus()
  }, [autoFocus])
}
