import React from 'react'
import cn from 'classnames'
import { isObject, isString } from 'lodash'

import styles from './styles.module.scss'

const VARIANT_TO_CLASSNAME = {
  dark: styles.variantDark,
  light: styles.variantLight,
}

export type SpinnerProps = {
  className?: string
  centered?: boolean
  variant?:
    | 'dark'
    | 'light'
    | {
        color?: string
        innerColor?: string
      }
}

export function Spinner(props: SpinnerProps) {
  const { centered = true, className, variant = 'dark' } = props

  const [outsideStyle, insideStyle] = isObject(variant)
    ? [{ background: variant?.color }, { background: variant?.innerColor }]
    : [undefined, undefined]

  return (
    <div
      className={cn(
        styles.spinner,
        centered && styles.centered,
        isString(variant) && VARIANT_TO_CLASSNAME[variant],
        className,
      )}
    >
      <div className={styles.inner}>
        <div className={styles.mainshell} style={outsideStyle} />
        <div className={styles.edge} style={outsideStyle} />
        <div className={styles.cshell} style={outsideStyle} />
        <div className={styles.inside} style={insideStyle} />
      </div>
    </div>
  )
}
