import React, { useCallback, useState, useEffect } from 'react'
import cn from 'classnames'
import { every, fill, isEqual } from 'lodash'
import styles from './styles.module.scss'
import {
  SelectionControlProps,
  Checkbox,
  RadioButton,
  RadioButtonProps,
  SelectionControl,
  CheckboxProps,
} from './SelectionControl'

export interface GroupProps {
  children: React.ReactElement<SelectionControlProps, typeof SelectionControl>[]
  type: 'checkbox' | 'radio'
  checked?: boolean[]
  direction?: 'vertical' | 'horizontal'
  readOnly?: boolean
  onChange?: (event: { checked: boolean; value: any }[]) => void
}

export function Group(props: GroupProps) {
  const { checked, direction = 'horizontal', children, onChange, type, readOnly } = props

  if (
    (type === 'checkbox' &&
      !every(React.Children.map(children, (child) => child.type === Checkbox))) ||
    (type === 'radio' &&
      !every(React.Children.map(children, (child) => child.type === RadioButton)))
  ) {
    throw new Error(`Selection control group type is ${type}, but some childs type differ from it.`)
  }

  const [checkedStates, setChecked] = useState<boolean[]>(
    () => checked || React.Children.map(children, (child) => child.props.defaultChecked || false),
  )

  useEffect(() => {
    if (checked && !isEqual(checkedStates, checked)) setChecked(checked)
  }, [checked])
  const handleChange = useCallback(
    (event, changeIndex) => {
      let newCheckedState = checkedStates

      if (type === 'checkbox') {
        newCheckedState = [
          ...checkedStates.slice(0, changeIndex),
          event.target.checked,
          ...checkedStates.slice(changeIndex + 1),
        ]
      } else if (type === 'radio') {
        newCheckedState = [
          ...fill(new Array(changeIndex), false),
          event.target.checked,
          ...fill(new Array(React.Children.count(children) - changeIndex - 1), false),
        ]
      }

      setChecked(newCheckedState)

      if (onChange) {
        onChange(
          React.Children.map(children, (child, index) => ({
            checked: newCheckedState[index],
            value: child.props.value,
          })),
        )
      }
    },
    [checkedStates, onChange, type],
  )

  return (
    <div
      className={cn(styles.group, {
        [styles.horizontalGroup]: direction === 'horizontal',
        [styles.verticalGroup]: direction === 'vertical',
      })}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ...child.props,
          checked: checkedStates[index],
          readOnly: readOnly || child.props.readOnly,
          onChange: (event: React.ChangeEvent) => {
            child.props.onChange && child.props.onChange(event)
            handleChange(event, index)
          },
        }),
      )}
    </div>
  )
}

export type CheckboxGroupProps = Omit<GroupProps, 'type' | 'children'> & {
  children: React.ReactElement<CheckboxProps, typeof Checkbox>[]
}

export function CheckboxGroup(props: CheckboxGroupProps) {
  return (
    <Group {...props} type='checkbox'>
      {props.children as GroupProps['children']}
    </Group>
  )
}

export type RadioGroupProps = Omit<GroupProps, 'type'> & {
  children: React.ReactElement<RadioButtonProps, typeof RadioButton>[]
}

export function RadioGroup(props: RadioGroupProps) {
  return (
    <Group {...props} type='radio'>
      {props.children as GroupProps['children']}
    </Group>
  )
}
