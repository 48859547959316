import React, { forwardRef, useState, useCallback, useEffect } from 'react'
import { assign } from 'lodash'
import cn from 'classnames'
import MaskedInput, { MaskedInputProps } from 'react-maskedinput'

import styles from './styles.module.scss'

const VARIANT_TO_CLASSNAME = {
  normal: styles.variantNormal,
}

const EXTENT_TO_CLASSNAME = {
  medium: styles.sizeMedium,
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  changed?: (value: string) => void
  mask?: string
  placeholderChar?: string
  extent?: 'medium'
  variant?: 'normal'
  label?: string
  invalid?: boolean
}

interface Input {}

export const Input = forwardRef((props: InputProps, ref: React.Ref<Input>) => {
  const {
    extent = 'medium',
    variant = 'normal',
    type = 'text',
    changed,
    onBlur,
    onChange,
    onFocus,
    className,
    disabled,
    label,
    mask,
    placeholderChar,
    invalid,
    value,
    ...restProps
  } = props

  const [filled, setFilled] = useState(!!value)
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    setFilled(!!value)
  }, [value])

  const handleFocus = useCallback(
    (event) => {
      setFocused(true)
      onFocus && onFocus(event)
    },
    [onFocus, setFocused],
  )

  const handleBlur = useCallback(
    (event) => {
      setFocused(false)
      setFilled(!!event.target.value)
      onBlur && onBlur(event)
    },
    [onBlur, value],
  )

  const handleChange = useCallback(
    (event) => {
      const { value } = event.target

      onChange && onChange(event)
      changed && changed(value)
    },
    [changed, onChange],
  )

  let Control: keyof React.ReactHTML | React.ComponentType = 'input'
  const controlSpecificProps: { type?: string } | MaskedInputProps = { type }

  if (mask) {
    Control = MaskedInput
    assign(controlSpecificProps, {
      mask,
      placeholderChar,
    })
  }

  return (
    <span
      className={cn(
        styles.input,
        className,
        VARIANT_TO_CLASSNAME[variant],
        EXTENT_TO_CLASSNAME[extent],
        {
          [styles.focused]: focused,
          [styles.filled]: filled || !!mask,
          [styles.disabled]: disabled,
          [styles.error]: invalid,
          [styles.masked]: !!mask,
        },
      )}
    >
      <Control
        {...restProps}
        {...controlSpecificProps}
        className={styles.control}
        disabled={disabled}
        // @ts-ignore
        ref={ref}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        value={value}
      />
      <span className={styles.label}>{label}</span>
    </span>
  )
})
