(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash"), require("classnames"), require("@chakra-ui/system"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash", "classnames", "@chakra-ui/system", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["@daisy-inc/contest-uikit"] = factory(require("react"), require("lodash"), require("classnames"), require("@chakra-ui/system"), require("react-router-dom"));
	else
		root["@daisy-inc/contest-uikit"] = factory(root["react"], root["lodash"], root["classnames"], root["@chakra-ui/system"], root["react-router-dom"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__12__) {
return 