import React from 'react'
import { capitalize } from 'lodash'
import { Button, ButtonProps } from './Button'

function createButtonVariant(variant: ButtonProps['variant']) {
  function ButtonVariant(props: Omit<ButtonProps, 'variant'>) {
    return <Button {...props} variant={variant} />
  }
  ButtonVariant.displayName = `${capitalize(variant)}Component`

  return ButtonVariant
}

export const BasicButton = createButtonVariant('basic')

export const CleanButton = createButtonVariant('clean')

export * from './Button'
