import React from 'react'
import cn from 'classnames'
import { isString } from 'lodash'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { SetOptional, Except } from 'type-fest'
import { chakra, ChakraProps } from '@chakra-ui/system'
import styles from './styles.module.scss'

const VARIANT_CLASSNAME = {
  clean: styles.variantClean,
  normal: styles.variantNormal,
}

export interface BaseLinkProps extends Except<SetOptional<NavLinkProps, 'to'>, 'color'> {
  variant?: 'clean' | 'normal'
}

export interface LinkProps extends BaseLinkProps, ChakraProps {}

export const Link = chakra((props: BaseLinkProps) => {
  const { variant = 'normal', ...restProps } = props
  restProps.className = cn(styles.link, restProps.className, VARIANT_CLASSNAME[variant])

  if (isString(restProps.to)) {
    return <NavLink {...(restProps as NavLinkProps)} />
  }

  return <a {...restProps} />
})
