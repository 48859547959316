import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { isBoolean } from 'lodash'

import useAutoFocus from 'hooks/useAutoFocus'
import styles from './styles.module.scss'

const TYPE_TO_CLASSNAME = {
  checkbox: styles.checkbox,
  radio: styles.radio,
}

const VARIANT_TO_CLASSNAME = {
  dark: styles.variantDark,
  light: styles.variantLight,
  alemira: styles.variantAlemira,
}

const SIZE_TO_CLASSNAME = {
  medium: styles.sizeMedium,
}

const KIND_TO_CLASSNAME = {
  button: styles.kindButton,
}

export type SelectionControlProps = {
  type: 'checkbox' | 'radio'
  autoFocus?: boolean
  checked?: boolean
  defaultChecked?: boolean
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  inline?: boolean
  kind?: 'basic' | 'button'
  size?: 'medium'
  variant?: 'dark' | 'light' | 'alemira'
  readOnly?: boolean
  value?: string
  onChange?: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
}

export function SelectionControl(props: SelectionControlProps) {
  const {
    size = 'medium',
    variant = 'dark',
    kind = 'basic',
    checked,
    defaultChecked,
    children,
    className,
    disabled,
    inline,
    onChange,
    onFocus,
    type,
    value,
    readOnly,
  } = props

  const [checkedState, setChecked] = useState(() => checked || defaultChecked || false)
  useEffect(() => {
    if (isBoolean(checked) && checked !== checkedState) setChecked(checked)
  }, [checked, checkedState])

  const handleChange = useCallback(
    (event) => {
      if (readOnly) return
      setChecked(event.target.checked)
      onChange && onChange(event)
    },
    [checked, onChange, value],
  )

  const controlRef = useRef(null)
  useAutoFocus(controlRef, props)

  return (
    <label
      className={cn(
        styles.selectionControl,
        className,
        SIZE_TO_CLASSNAME[size],
        TYPE_TO_CLASSNAME[type],
        VARIANT_TO_CLASSNAME[variant],
        kind && KIND_TO_CLASSNAME[kind],
        {
          [styles.inline]: inline,
          [styles.disabled]: disabled,
          [styles.checked]: checkedState,
          [styles.readOnly]: readOnly,
        },
      )}
    >
      <input
        autoComplete='off'
        className={styles.control}
        checked={checkedState}
        disabled={disabled}
        readOnly={readOnly}
        type={type}
        value={value}
        onChange={handleChange}
        onFocus={onFocus}
      />
      {kind !== 'button' && <div className={styles.check} />}
      <span className={styles.label}>{children}</span>
    </label>
  )
}

export type CheckboxProps = Omit<SelectionControlProps, 'type'>

export function Checkbox(props: CheckboxProps) {
  return <SelectionControl type='checkbox' {...props} />
}

export type RadioButtonProps = Omit<SelectionControlProps, 'type'>

export function RadioButton(props: RadioButtonProps) {
  return <SelectionControl type='radio' {...props} />
}
